import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useLayoutStore } from "~/store";
import { Heading, BtnBack, Icon } from "~/components";
import { TopBanner } from "./TopBanner";
import { UpdateProfile } from "./UpdateProfile";
import { CardsLayout, CarouselLayout } from "./layouts";
import { useExplorePassions } from "./useExplorePassions";
import styles from "./ExplorePage.module.css";

import { data } from "./data";

export const ExplorePage = ({ showBanner = false, experienceType, view }) => {
  const { isResetting, setContentClassName } = useLayoutStore();
  const passions = useExplorePassions(experienceType);

  useEffect(() => {
    if (isResetting) {
      return;
    }

    setContentClassName(styles.parentContent);
  }, [isResetting]);

  // Select layout
  const [searchParams] = useSearchParams();
  const selectedView = useMemo(() => {
    const defaultView = <CardsLayout passions={passions} allPassions={data} />;
    const selected = view || Number(searchParams.get("view")) || 1;

    return (
      {
        1: defaultView,
        2: (
          <CarouselLayout
            className={styles.carousel}
            passions={passions}
            allPassions={data}
          />
        ),
      }[selected] ?? defaultView
    );
  }, [data, passions, view, searchParams]);

  return (
    <>
      <BtnBack
        trackValue="Explore Header|Back to Experience"
        type="inversion"
        className={styles.back}
      >
        <Icon name="arrowRight" size="16" className={styles.backIcon} />
        Back
      </BtnBack>
      {showBanner && <TopBanner passions={passions} />}
      <Heading
        type="h1"
        text="Explore Your Passions"
        className={styles.heading}
      />
      <p className={styles.subheader}>
        Discover experiences to enrich your next trip with Marriott Bonvoy®.
      </p>
      <div className={styles.wrapper}>
        {selectedView}
        <UpdateProfile />
      </div>
    </>
  );
};

import { useState, useEffect, useCallback } from "react";
import debounce from "debounce";

export const useHasScroll = (elementRef, children) => {
  const [hasScroll, setHasScroll] = useState(false);

  const updateHasScroll = useCallback(() => {
    const { current } = elementRef;

    if (!current) {
      return;
    }

    setHasScroll(current.scrollWidth > current.clientWidth);
  }, [elementRef, setHasScroll]);

  const handleResize = useCallback(debounce(updateHasScroll, 100), [
    updateHasScroll,
  ]);

  useEffect(() => {
    const { current } = elementRef;

    if (!current) {
      return;
    }

    updateHasScroll();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [elementRef, children]);

  return hasScroll;
};

import React, { useEffect } from "react";
import classNames from "classnames";
import { useLayoutStore } from "~/store";
import { Heading } from "~/components";
import styles from "./NotFoundPage.module.css";
import logo from "../../assets/images/marriott-logo.png";

export const NotFoundPage = ({ trackValue = "Not Found Page|Home Button" }) => {
  const { isResetting, setShowHeader, setContentClassName } = useLayoutStore();

  useEffect(() => {
    if (isResetting) {
      return;
    }

    setShowHeader(false);
    setContentClassName(styles.container);
  }, [isResetting]);

  return (
    <section className={styles.box}>
      <img alt="Marriott logo" src={logo} className={styles.logo} />
      <Heading
        type="h1"
        text="Whoops! We hit a dead end."
        className={styles.heading}
      />
      <p className={styles.subheader}>
        The page you’re looking for couldn’t be found.
      </p>
      <a
        href="https://www.marriott.com"
        className={classNames(styles.btn, "custom_click_track")}
        data-custom_click_track_value={`${trackValue}|internal`}
      >
        Go Home
      </a>
    </section>
  );
};

import { useState, useEffect } from "react";

export const useShowButtons = ({
  childrenCount,
  hasScroll,
  isStart,
  isEnd,
}) => {
  const [mountButtons, setMountButtons] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);

  useEffect(() => {
    const showBoth = childrenCount > 3 || hasScroll;

    setMountButtons(showBoth);
    setShowPrev(showBoth && !isStart);
    setShowNext(showBoth && !isEnd);
  }, [childrenCount, hasScroll, isStart, isEnd]);

  return {
    mountButtons,
    showPrev,
    showNext,
    hidePrev: !showPrev,
    hideNext: !showNext,
  };
};

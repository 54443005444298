import React, { useRef, useEffect, useCallback } from "react";
import classNames from "classnames";
import { BtnUi, Icon } from "~/components";
import { CarouselItem } from "./CarouselItem";
import { useHasScroll } from "./useHasScroll";
import { useScrollTo } from "./useScrollTo";
import { useShowButtons } from "./useShowButtons";
import { useInfiniteScroll } from "./useInfiniteScroll";
import styles from "./CarouselLayout.module.css";

export const CarouselLayout = ({ className, allPassions, passions }) => {
  const carouselRef = useRef(null);
  const carouselChildren = useInfiniteScroll(carouselRef, passions);
  const hasScroll = useHasScroll(carouselRef, carouselChildren);
  const scrollTo = useScrollTo(carouselRef);
  const { mountButtons, hidePrev, hideNext } = useShowButtons({
    childrenCount: carouselChildren,
    hasScroll,
    isStart: false,
    isEnd: false,
  });

  // Scroll on arrows
  const onArrows = useCallback(
    ({ key }) => {
      key === "ArrowLeft" && scrollTo(-300);
      key === "ArrowRight" && scrollTo(300);
    },
    [scrollTo],
  );

  useEffect(() => {
    window.addEventListener("keyup", onArrows);
    return () => window.removeEventListener("keyup", onArrows);
  }, [onArrows]);

  return (
    <div className={classNames(styles.layout, className)}>
      {mountButtons && (
        <BtnUi
          className={classNames(styles.btn, styles.btnLeft, {
            [styles.btnHidden]: hidePrev,
          })}
          onClick={scrollTo.bind(this, -300)}
          disabled={hidePrev}
        >
          <Icon name="arrowRightNoLine" inverse={true} />
        </BtnUi>
      )}
      <div className={styles.carousel} ref={carouselRef}>
        <div className={styles.carouselInner}>
          {carouselChildren.map((item, i) => (
            <CarouselItem key={i} item={item} allPassions={allPassions} />
          ))}
        </div>
      </div>
      {mountButtons && (
        <BtnUi
          className={classNames(styles.btn, styles.btnRight, {
            [styles.btnHidden]: hideNext,
          })}
          onClick={scrollTo.bind(this, 300)}
          disabled={hideNext}
        >
          <Icon name="arrowRightNoLine" />
        </BtnUi>
      )}
    </div>
  );
};

import React, { useEffect, useState, useCallback } from "react";
import styles from "./VisualPage.module.css";
import HeartIcon from "../../assets/common/heart-outline.svg";
import HeartFillIcon from "../../assets/common/heart-fill.svg";
import { usePassions } from "../../lib/hooks/use-passions";
import { useNavigate, useLocation } from "react-router-dom";
import { useCampaignPage } from "~/store";
import { useAcid } from "../../lib/hooks/use-acid";
import {
  getAnalyticsPageName,
  sendAnalyticsPageViewEvent,
  sendAnalyticsSubmitEvent,
} from "../../utils/adobe-data-layer-utils";
import {
  Heading,
  BtnUi,
  PreferencesNoticeUi,
  SignInDialogUi,
} from "~/components";
import classNames from "classnames";

const data = [
  {
    label: "Beach",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/si-nanti-snorkeling-35248",
    passion: "beach",
  },
  {
    label: "Culinary",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/fp-aklfp-wine-dinner-20235?wid=316&fit=constrain",
    passion: "culinary",
  },
  {
    label: "Music",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/mc-tricc-jazz-musicians-23095?wid=316&fit=constrain",
    passion: "music",
  },
  {
    label: "Winter Sports",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/tx-szgjf-skiing-31248?wid=316&fit=constrain",
    passion: "ski",
  },
  {
    label: "Golf",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/mc-wasbd-golf-course-35141",
    passion: "golf",
  },
  {
    label: "Spa",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/50514591-espa_privatespasuite_outdoor_sized_tile?wid=316&fit=constrain",
    passion: "spa",
  },
  {
    label: "Arts & Culture",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/cy-sfobc-cable-car-with-alcatraz-23185",
    passion: "culture",
  },
];

export const VisualPage = ({
  mode = "standard",
  experienceType = "visual",
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { acid } = useAcid();
  const {
    selectPassion,
    unselectPassion,
    isPassionSelected,
    isAnyPassionSelected,
    savePassionsToServer,
    getSelectedPassionsAsArray,
    getSelectedPassionsAsFlatArray,
  } = usePassions(experienceType);
  const [showSignInDialog, setShowSignInDialog] = useState(false);
  const { setShowPageLoader } = useCampaignPage();

  useEffect(() => {
    if (mode === "derived") {
      const pageName = getAnalyticsPageName(
        location,
        "marriott",
        "derived-visual",
      );
      sendAnalyticsPageViewEvent(pageName);
    }
  }, []);

  const handlePassionClick = (passion) => {
    if (isPassionSelected(passion)) {
      unselectPassion(passion);
    } else {
      selectPassion(passion);
    }
  };

  let header = (
    <div className={styles.header}>
      <Heading type="h1" text="Share Your Travel Passions" />
      <p className={styles.subheader}>
        Select your interests to unlock personalized recommendations and offers.
      </p>
    </div>
  );
  if (mode === "derived") {
    header = (
      <div className={classNames(styles.header, styles.headerShort)}>
        <Heading type="h1" text="Tell us what else you’re interested in." />
      </div>
    );
  }

  const handleSubmit = useCallback(async () => {
    if (!acid) {
      setShowSignInDialog(true);
      return;
    }

    sendAnalyticsSubmitEvent(getSelectedPassionsAsArray());
    await savePassionsToServer(acid, experienceType);
    const explore =
      mode === "standard" ? "/travel/explore" : "/derived-visual/explore";
    navigate(explore);
  }, [
    acid,
    setShowSignInDialog,
    getSelectedPassionsAsArray,
    savePassionsToServer,
    navigate,
  ]);

  const onCloseDialog = useCallback(
    () => setShowSignInDialog(false),
    [setShowSignInDialog],
  );

  const onSubmitDialog = useCallback(() => {
    onCloseDialog();
    setShowPageLoader(true);
  }, [onCloseDialog, setShowPageLoader]);

  return (
    <>
      {header}
      <div className={styles.cardGrid} data-mode={mode}>
        {data
          .filter((d) => {
            if (mode === "derived") {
              return d.passion !== "golf";
            }

            return true;
          })
          .map((d) => (
            <div
              className={`${styles.card} custom_click_track`}
              data-custom_click_track_value={`Travel|${d.label}|internal`}
              key={d.label}
              onClick={() => handlePassionClick(d.passion)}
            >
              <img src={d.image} alt="Card image" />
              <div className={styles.cardContent}>
                <img
                  className={styles.cardIcon}
                  src={isPassionSelected(d.passion) ? HeartFillIcon : HeartIcon}
                  alt="heart icon"
                />
                <span className={styles.cardText}>{d.label}</span>
              </div>
            </div>
          ))}
      </div>
      <div
        className={classNames(styles.buttonContainer, {
          [styles.buttonContainerSticky]: mode === "derived",
        })}
      >
        <BtnUi
          className={`${styles.explore} custom_click_track`}
          data-custom_click_track_value={`Travel Footer|Save Continue|internal`}
          disabled={!isAnyPassionSelected()}
          onClick={handleSubmit}
        >
          Save & Continue
        </BtnUi>
        <PreferencesNoticeUi />
      </div>
      {showSignInDialog && (
        <SignInDialogUi
          onClose={onCloseDialog}
          onSubmit={onSubmitDialog}
          selectedPassions={getSelectedPassionsAsFlatArray()}
        />
      )}
    </>
  );
};

import { useSearchParams } from "react-router-dom";
import { usePassions } from "~/lib/hooks/use-passions";
import stylesBanner from "./TopBanner/TopBanner.module.css";

const passionsParams = [
  "golf",
  "beach",
  "ski",
  "culinary",
  "culture",
  "music",
  "spa",
];

const getBannerSize = (tapsCount) => {
  return tapsCount > 1 ? stylesBanner.love : stylesBanner.like;
};

export const useExplorePassions = (experienceType) => {
  const [searchParams] = useSearchParams();
  const { isPassionSelected, getPassionValue } = usePassions(experienceType);

  const passion = searchParams.get("passion");

  if (passion && passionsParams.includes(passion)) {
    const taps = getPassionValue(passion);

    return [
      {
        passion,
        taps,
        style: getBannerSize(taps),
      },
    ];
  }

  const passions = passionsParams
    .map((passion) => {
      if (!isPassionSelected(passion)) {
        return null;
      }

      const taps = getPassionValue(passion);

      return { passion, taps, style: getBannerSize(taps) };
    })
    .filter(Boolean)
    .sort((a, b) => b.taps - a.taps);

  return passions;
};

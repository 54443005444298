import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import ChooseCampaign from "./multiCampaign/ChooseCampaign";
import TryAuth from "./TryAuth";
import { CampaignPage, NotFoundPage, ExplorePage } from "../pages";
import { Heading } from "./Heading";
import { TitleBarLayout } from "./layout";
import PageViewTracking from "./analytics/PageViewTracking";
import UserDataTracking from "./analytics/UserDataTracking";

import "../styles/global/fonts.css";
import "../styles/global/theme.css";

function App(props) {
  console.log("runtime object:", props.runtime);
  console.log("ims object:", props.ims);

  // use exc runtime event handlers
  // respond to configuration change events (e.g. user switches org)
  props.runtime.on("configuration", ({ imsOrg, imsToken, locale }) => {
    console.log("configuration change", { imsOrg, imsToken, locale });
  });
  // respond to history change events
  props.runtime.on("history", ({ type, path }) => {
    console.log("history change", { type, path });
  });

  return (
    <ErrorBoundary onError={onError} FallbackComponent={fallbackComponent}>
      <Router>
        <UserDataTracking />
        <PageViewTracking />
        <TitleBarLayout>
          <Routes>
            <Route path="/campaign" element={<CampaignPage />} />
            <Route path="/multiCampaign" element={<ChooseCampaign />} />
            <Route
              path="/share/explore"
              element={<ExplorePage experienceType="bubbles" showBanner />}
            />
            <Route
              path="/video/explore"
              element={<ExplorePage experienceType="video" showBanner />}
            />
            <Route
              path="/follow/explore"
              element={<ExplorePage experienceType="follow" />}
            />
            <Route
              path="/travel/explore"
              element={<ExplorePage experienceType="visual" />}
            />
            <Route
              path="/derived-visual/explore"
              element={<ExplorePage experienceType="derived-visual" />}
            />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<TryAuth />} />
          </Routes>
        </TitleBarLayout>
      </Router>
    </ErrorBoundary>
  );

  // Methods

  // error handler on UI rendering failure
  function onError() {}

  // component to show if UI fails rendering
  function fallbackComponent({ componentStack, error }) {
    return (
      <React.Fragment>
        <Heading
          type="h1"
          text="Something went wrong :("
          style={{ textAlign: "center", marginTop: "20px" }}
        />
        <pre>{componentStack + "\n" + error.message}</pre>
      </React.Fragment>
    );
  }
}

export default App;

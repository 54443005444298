import React from "react";

export const SpaIcon = (props) => (
  <svg
    width="84"
    height="84"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M56.5416 32.125C55.7916 20.5 51.0833 9.16665 42.25 0.333313C33.3333 9.24998 28.4166 20.5833 27.4583 32.125C32.7916 34.9583 37.7083 38.625 42 43.0833C46.2916 38.6666 51.2083 35 56.5416 32.125ZM42.2083 13.625C44.8333 17.9166 46.6667 22.7083 47.625 27.7083C45.6667 28.9583 43.8333 30.3333 42.0416 31.7916C40.2916 30.375 38.4166 29 36.5 27.75C37.5416 22.75 39.4583 17.9583 42.2083 13.625ZM42 56.375C38.5833 51.1666 34.25 46.625 29.25 43.0416C28.7083 42.6666 28.125 42.375 27.5833 41.9583C28.125 42.3333 28.7083 42.6666 29.2083 43C21.0833 37.125 11.125 33.6666 0.333313 33.6666C0.333313 55.8333 14.3333 74.5833 33.7916 81.5416C36.4166 82.5 39.1666 83.2083 42 83.6667C44.8333 83.1666 47.5417 82.4583 50.2083 81.5416C69.6667 74.5833 83.6667 55.8333 83.6667 33.6666C66.25 33.6666 50.9583 42.7083 42 56.375ZM47.5 73.6667C45.6666 74.2917 43.8333 74.7916 41.9583 75.2083C40.125 74.8333 38.3333 74.3333 36.625 73.7083C22.9166 68.7917 12.875 57.0833 9.74998 43.0833C14.3333 44.1666 18.7083 46.0416 22.75 48.625L22.6666 48.6667C23.2083 49.0417 23.75 49.4167 24.2916 49.7083L24.5833 49.875C28.7083 52.875 32.25 56.5833 35.0416 60.9167L42 71.5833L48.9583 60.9583C51.8333 56.5833 55.4167 52.8333 59.5 49.875L59.7917 49.6666C60.1667 49.4583 60.5417 49.2083 60.9167 48.9583L60.875 48.875C64.9583 46.1666 69.5 44.1666 74.25 43.0416C71.125 57.0833 61.125 68.7917 47.5 73.6667Z"
      fill="currentColor"
    />
  </svg>
);

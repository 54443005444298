import React from "react";
import classNames from "classnames";
import styles from "./CarouselItem.module.css";

export const CarouselItem = ({ allPassions, item }) => {
  const [itemData] = allPassions[item.passion].slides;
  const { image, headline, label, url, analyticsDesc } = itemData;
  const backgroundImage = `linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent 55%), url(${image})`;

  return (
    <div className={styles.item} style={{ backgroundImage }}>
      <div className={styles.title}>{headline}</div>
      <p className={styles.text}>{label}</p>
      <a
        className={classNames(styles.link, "custom_click_track")}
        data-custom_click_track_value={`Explore|${analyticsDesc}|internal`}
        href={url}
      >
        Explore
      </a>
    </div>
  );
};

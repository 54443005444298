import React from "react";
import { ExploreCard } from "./ExploreCard";
import styles from "./CardsLayout.module.css";

export const CardsLayout = ({ allPassions, passions }) => {
  return (
    <div className={styles.list}>
      {passions.map((p) => (
        <ExploreCard
          key={p.passion}
          passionType={p.passion}
          allPassions={allPassions}
        />
      ))}
    </div>
  );
};

import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { BtnUi, Icon } from "~/components";
import styles from "./DialogUi.module.css";

export const DialogUi = ({ header, body, footer, onClose, ...props }) => {
  // Close on overlay click
  const closeOnOverlay = useCallback(
    ({ target }) => {
      if (target.className === styles.overlay) {
        onClose();
      }
    },
    [onClose],
  );

  // Close on Esc
  useEffect(() => {
    const onEscape = ({ key }) => key === "Escape" && onClose();
    window.addEventListener("keyup", onEscape);

    return () => window.removeEventListener("keyup", onEscape);
  }, [onClose]);

  return createPortal(
    <div className={styles.overlay} {...props} onClick={closeOnOverlay}>
      <section className={styles.dialog}>
        <div className={styles.dialogHeader}>
          <BtnUi variant="icon" className={styles.close} onClick={onClose}>
            <Icon name="close" className={styles.closeIcon} />
          </BtnUi>
          {header}
        </div>
        <div className={styles.dialogBody}>{body}</div>
        <div className={styles.dialogFooter}>{footer}</div>
      </section>
    </div>,
    document.body,
  );
};

import React from "react";

export const MusicIcon = (props) => (
  <svg
    width="72"
    height="76"
    viewBox="0 0 72 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1667 0.5V17.1667V25.5V51.2324C15.8295 50.7533 14.4204 50.5056 13 50.5C9.68479 50.5 6.50537 51.817 4.16117 54.1612C1.81696 56.5054 0.5 59.6848 0.5 63C0.5 66.3152 1.81696 69.4946 4.16117 71.8388C6.50537 74.183 9.68479 75.5 13 75.5C16.3152 75.5 19.4946 74.183 21.8388 71.8388C24.183 69.4946 25.5 66.3152 25.5 63V25.5H63V51.2324C61.6628 50.7533 60.2538 50.5056 58.8333 50.5C55.5181 50.5 52.3387 51.817 49.9945 54.1612C47.6503 56.5054 46.3333 59.6848 46.3333 63C46.3333 66.3152 47.6503 69.4946 49.9945 71.8388C52.3387 74.183 55.5181 75.5 58.8333 75.5C62.1485 75.5 65.328 74.183 67.6722 71.8388C70.0164 69.4946 71.3333 66.3152 71.3333 63V25.5V17.1667V0.5H17.1667Z"
      fill="currentColor"
    />
  </svg>
);

import React from "react";

export const SkiIcon = (props) => (
  <svg
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.8335 3.49996C27.8335 5.33329 26.3335 6.83329 24.5002 6.83329C22.6668 6.83329 21.1668 5.33329 21.1668 3.49996C21.1668 1.66663 22.6668 0.166626 24.5002 0.166626C26.3335 0.166626 27.8335 1.66663 27.8335 3.49996ZM23.3002 30.8333L24.5668 31.2833C25.6002 31.6333 26.6835 31.8333 27.8335 31.8333C29.2335 31.8333 30.5835 31.5333 31.8002 31L33.6668 32.8833C31.9168 33.8 29.9335 34.3333 27.8335 34.3333C26.4002 34.3333 25.0335 34.1 23.7502 33.65L0.333496 25.1166L1.16683 22.7666L12.6668 26.95L15.5335 19.55L9.5835 13.3333C8.10016 11.7666 8.46683 9.21663 10.3335 8.13329L16.1335 4.78329C17.9668 3.71663 20.3335 4.61663 20.9835 6.63329L21.5335 8.43329C22.2668 10.8 24.0002 12.7166 26.2502 13.6666L27.1168 11L29.5002 11.7666L27.6335 17.5166C23.5502 16.85 20.1668 14.0666 18.6668 10.3166L14.4502 12.7333L19.5002 18.5L15.8002 28.1L20.9502 29.9666L24.4502 19.2333C25.2168 19.5333 26.0168 19.75 26.8502 19.9166L23.3002 30.8333Z"
      fill="currentColor"
    />
  </svg>
);

import React, { useEffect } from "react";
import styles from "./BubblePage.module.css";
import { JigglingCircles } from "./JigglingCircles";
import { useLayoutStore } from "~/store";
import { Heading } from "../Heading";

export const BubblePage = () => {
  const { isResetting, setContentClassName, setShowHeader } = useLayoutStore();

  useEffect(() => {
    if (isResetting) {
      return;
    }

    setContentClassName(styles.parentContent);
    setShowHeader(true);
  }, [isResetting]);

  return (
    <>
      <div className={styles.banner}>
        <Heading type="h1" text="Share Your Passions" />
        <p className={styles.subheader}>
          Tap once on the ones that you like and twice on the ones that you
          love.
        </p>
      </div>
      <div style={{ overflow: "hidden" }}>
        <JigglingCircles />
      </div>
    </>
  );
};

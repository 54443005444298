import React from "react";

export const HeartIcon = (props) => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.75 0C13.01 0 11.34 0.81 10.25 2.09C9.16 0.81 7.49 0 5.75 0C2.67 0 0.25 2.42 0.25 5.5C0.25 9.28 3.65 12.36 8.8 17.04L10.25 18.35L11.7 17.03C16.85 12.36 20.25 9.28 20.25 5.5C20.25 2.42 17.83 0 14.75 0ZM10.35 15.55L10.25 15.65L10.15 15.55C5.39 11.24 2.25 8.39 2.25 5.5C2.25 3.5 3.75 2 5.75 2C7.29 2 8.79 2.99 9.32 4.36H11.19C11.71 2.99 13.21 2 14.75 2C16.75 2 18.25 3.5 18.25 5.5C18.25 8.39 15.11 11.24 10.35 15.55Z"
      fill="currentColor"
    />
  </svg>
);

import React from "react";
import classNames from "classnames";
import styles from "./PreferencesNoticeUi.module.css";

export const PreferencesNoticeUi = ({ className, ...props }) => {
  return (
    <p className={classNames(styles.notice, className)} {...props}>
      Your selections will be saved to your Marriott Bonvoy profile and used to
      provide you tailored experiences and offers.
    </p>
  );
};

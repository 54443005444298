import React from "react";
import classNames from "classnames";
import * as icons from "./icons";
import styles from "./Icon.module.css";

export const Icon = ({
  name,
  className,
  size = 24,
  inverse = false,
  ...props
}) => {
  const Element = icons[`${name[0].toUpperCase()}${name.slice(1)}Icon`];

  return (
    <div
      className={classNames(styles.icon, styles[`icon-${size}`], className, {
        [styles.iconInverse]: inverse,
      })}
    >
      <Element className={styles.iconSvg} {...props} />
    </div>
  );
};

import React from "react";
import classNames from "classnames";
import styles from "./UpdateProfile.module.css";

export const UpdateProfile = () => {
  return (
    <section className={styles.box}>
      <div>Is your profile up-to-date?</div>
      <div>
        By keeping your profile updated, we can give you content that is most
        relevant to you.
      </div>
      <a
        href="https://www.marriott.com/loyalty/myAccount/profile.mi"
        className={classNames(styles.btn, "custom_click_track")}
        data-custom_click_track_value="Explore Page|Update Profile Button|internal"
      >
        Update Your Profile
      </a>
    </section>
  );
};

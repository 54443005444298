import React from "react";

export const CulinaryIcon = (props) => (
  <svg
    width="28"
    height="30"
    viewBox="0 0 28 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 0V10.5H6.5V0H9.5V10.5H12.5V0H15.5V9C15.5 12.315 12.815 15 9.5 15V30H6.5V15C3.185 15 0.5 12.315 0.5 9V0H3.5ZM27.5 0V30H24.5V19.5H18.5V6C18.5 1.94321 22.7778 0.0934107 27.1626 0.00345612L27.5 0ZM24.5 3.3465L24.4741 3.35255C22.6857 3.82581 21.6226 4.73266 21.51 5.80845L21.5 6V16.5H24.5V3.3465Z"
      fill="currentColor"
    />
  </svg>
);

import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import { useCampaignPage } from "~/store";
import { useSSORedirect } from "~/hooks";
import { buildIFrameUrl } from "../../utils/utils";
import { marriottCampaigns } from "./marriott-campaign-data";
import styles from "../../styles/RenderCampaign.module.css";
import LoadingPage from "../LoadingPage";
import { useAcid } from "../../lib/hooks/use-acid";
import { useCampaignParams } from "../../lib/hooks/use-campaign-params";
import { NotFoundPage } from "~/pages";

function RenderCampaign() {
  const location = useLocation();
  const { acid: uid } = useAcid();

  const [iFrameUrl, setIFrameUrl] = useState(null);
  const [marriottCampaignComponent, setMarriottCampaignComponent] =
    useState(null);
  const { showPageLoader, setShowPageLoader } = useCampaignPage();
  const ssoRedirect = useSSORedirect();
  const {
    cSource: cSourceValue,
    expId: cidValue,
    testParam: testParamValue,
    channelSrc: channelSrcValue,
  } = useCampaignParams();

  useEffect(() => {
    // resetting values once URL parameters have changed
    setShowPageLoader(true);
    setIFrameUrl(null);
    setMarriottCampaignComponent(null);

    if (!cSourceValue || !cidValue) {
      return setShowPageLoader(false);
    }

    if (!uid && !["share", "travel"].includes(cidValue)) {
      ssoRedirect();
      return;
    }

    if (cSourceValue.toLowerCase() === "jebbit") {
      const campaignUrl = buildIFrameUrl(
        cSourceValue,
        cidValue,
        testParamValue,
        channelSrcValue,
        uid,
      );
      setIFrameUrl(campaignUrl);
      return setShowPageLoader(false);
    }

    if (cSourceValue.toLowerCase() !== "marriott") {
      return setShowPageLoader(false);
    }

    // check if it's an existing marriott campaign type
    const marriottCampaign = marriottCampaigns.campaigns.find(
      ({ uuid }) => uuid === cidValue,
    );

    if (marriottCampaign?.campaignPage) {
      setMarriottCampaignComponent(marriottCampaign.campaignPage);
    }

    setShowPageLoader(false);
  }, [location.search]);

  const jebbitHTMLEmbedCode = (iFrameUrl) => {
    console.log("Rendering campaign using the following URL:", iFrameUrl);
    console.log("UID:", uid);
    return `
      <iframe class="jebbit-iframe" 
              src="${iFrameUrl}&deferred=true" 
              seamless="true" 
              frameborder="0" 
              webkitallowfullscreen 
              mozallowfullscreen 
              allowfullscreen 
              style="width:100%;min-height:95vh;height:100%;">
      </iframe>
      <script>
        function embedJebbit(t) {
          function e(t) {
            var e = 'attach' === t ? window.addEventListener : window.removeEventListener;
            e('DOMContentLoaded', n, !1);
            e('load', n, !1);
            e('scroll', n, !1);
            e('resize', n, !1);
          }
          var i = document.querySelector(t);
          function n() {
            var t, e, n, o;
            e = (t = i).getBoundingClientRect();
            n = t.clientHeight / 2;
            o = t.clientWidth / 2;
            e.top >= 0 && e.left >= 0 && e.top <= (window.innerHeight || document.documentElement.clientHeight) - n && e.left <= (window.innerWidth || document.documentElement.clientWidth) - o && i.contentWindow.postMessage('startJebbitCampaign', '*');
          }
          window.addEventListener('message', function(t) {
            t && 'https://marriott.jebbit.com' === t.origin && ('jebbitSessionCreated' === t.data ? e('remove', n) : 'jebbitCampaignLoaded' === t.data ? n() : t.data && 'redirect' === t.data.action && t.data.options && t.data.options.link && (window.location.href = t.data.options.link));
          }, !1);
          e('attach');
        }
        embedJebbit('.jebbit-iframe');
      </script>
      <script>
        window.addEventListener("message", function(t) {
          try {
            if (t && "https://marriott.jebbit.com" === t.origin && t.data && "updateIframeHeight" === t.data.action && t.data.jebbitIframeHeight) {
              let e = document.querySelector(".jebbit-iframe"),
                  i = t.data.jebbitIframeHeight,
                  a = e.clientHeight;
              if (e.style.height = i + "px", i < a && (a - i > 300 || a > 1300)) {
                let o = e.getBoundingClientRect().top,
                    n = document.body.getBoundingClientRect().top;
                window.scrollTo({ top: o - n, behavior: "smooth" });
              }
            }
          } catch (r) {
            return;
          }
        });
      </script>
    `;
  };
  const iFrameCode = iFrameUrl ? jebbitHTMLEmbedCode(iFrameUrl) : "";

  if (showPageLoader) {
    return <LoadingPage />;
  }

  if (iFrameUrl) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.content}>{parse(iFrameCode)}</div>
        </div>
      </div>
    );
  }

  if (marriottCampaignComponent) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.wrapper}>{marriottCampaignComponent}</div>
      </div>
    );
  }

  return <NotFoundPage trackValue="Campaign Not Found Page|Home Button" />;
}

export default RenderCampaign;

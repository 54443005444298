import React from "react";
import styles from "./ExploreCard.module.css";
import RightChevronCircle from "~/assets/common/right-cheveron-circle.svg";

export const ExploreCard = ({ allPassions, passionType }) => {
  const passion = allPassions[passionType].slides[0];

  return (
    <a
      className={`${styles.link} custom_click_track`}
      data-custom_click_track_value={`Explore|${passion.analyticsDesc}|internal`}
      href={passion.url}
    >
      <div className={styles.card}>
        <img className={styles.img} src={passion.image} alt="" />
        <div className={styles.content}>
          <div className={styles.title}>
            <h2>{passion.headline}</h2>
            <img src={RightChevronCircle} alt="" />
          </div>
          <p>{passion.label}</p>
        </div>
      </div>
    </a>
  );
};

import React from "react";

export const CultureIcon = (props) => (
  <svg
    width="84"
    height="84"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42 0.333313C19 0.333313 0.333336 19 0.333336 42C0.333336 65 19 83.6667 42 83.6667C65 83.6667 83.6667 65 83.6667 42C83.6667 19 65 0.333313 42 0.333313ZM8.66667 42C8.66667 39.4583 9 36.9583 9.54167 34.5833L29.4583 54.5V58.6666C29.4583 63.25 33.2083 67 37.7917 67V75.0417C21.4167 72.9583 8.66667 58.9583 8.66667 42ZM66.5417 64.5C65.4583 61.125 62.375 58.6666 58.625 58.6666H54.4583V46.1666C54.4583 43.875 52.5833 42 50.2917 42H25.2917V33.6666H33.625C35.9167 33.6666 37.7917 31.7916 37.7917 29.5V21.1666H46.125C50.7083 21.1666 54.4583 17.4166 54.4583 12.8333V11.125C66.6667 16.0416 75.3333 28.0416 75.3333 42C75.3333 50.6666 71.9583 58.5833 66.5417 64.5Z"
      fill="currentColor"
    />
  </svg>
);

import React from "react";
import "../../styles/follow/FollowExperiencePage.module.css";
import { FollowCard } from "./FollowCard";
import styles from "../../styles/follow/FollowExperiencePage.module.css";
import { Heading, PreferencesNoticeUi } from "~/components";

const cards = [
  {
    title: "Golf",
    followers: "102.6K",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/ak-nascv-nascv-golf-aerial-2-24070?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/rz-okarz-golf-ball-20922?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/wv-pspwd-golf-course-42060?wid=316&fit=constrain"
    ],
    category: "GOLF_LOV",
    value: "ENTHU",
    passion: "golf",
    analyticsDesc: "Golf"
  },
  {
    title: "Beach & Water Sports",
    followers: "84.2K",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/rz-hnlkz-beach-club-daybeds-41562?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/stock-tours-1073191?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/xr-mlexr-whale-bar-aerial-37919-47743?wid=316&fit=constrain"
    ],
    category: "INTERESTS_AND_HOBBIES_LOV",
    value: "BCH",
    passion: "beach",
    analyticsDesc: "Beach"
  },
  {
    title: "Mountain & Winter Sports",
    followers: "80.5K",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/ski-slope-breckenridge-colorado?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/tx-szgjf-skiing-31248?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/whistler-village-whistler-british-columbia-canada?wid=316&fit=constrain"
    ],
    category: "INTERESTS_AND_HOBBIES_LOV",
    value: "WNTRACT",
    passion: "ski",
    analyticsDesc: "Ski"
  },
  {
    title: "Culinary",
    followers: "98.6K",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/si-yyztc-food-drinks-38936?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/fp-aklfp-wine-dinner-20235?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/mc-chidt-culinary-27039?wid=316&fit=constrain"
    ],
    category: "INTERESTS_AND_HOBBIES_LOV",
    value: "COOK",
    passion: "culinary",
    analyticsDesc: "Culinary"
  },
  {
    title: "Local Culture & Excursions",
    followers: "62.8K",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/wi-selwi-tour-pkg-8-16634?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/rcdubai_00058_conversion?crop=0,350,3825,1455&fit=constrain,1&wid=316&hei=126",
      "https://cache.marriott.com/is/image/marriotts7prod/mc-tricc-jazz-musicians-23095?wid=316&fit=constrain"
    ],
    category: "INTERESTS_AND_HOBBIES_LOV",
    value: "CULTR",
    passion: "culture",
    analyticsDesc: "Culture"
  },
  {
    title: "Live Music & Concerts",
    followers: "42.6K",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/md-wasrl-getty-concert-639920934-38114?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/br-nycrt-enjoy-a-concert-at-msg-20887?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/cy-dalpn-stage-and-lawn-11778?wid=316&fit=constrain"
    ],
    category: "INTERESTS_AND_HOBBIES_LOV",
    value: "MUSIC",
    passion: "music",
    analyticsDesc: "Music"
  },
  {
    title: "Spa",
    followers: "58.4K",
    images: [
      "https://cache.marriott.com/is/image/marriotts7prod/br-sjubr-elemara-spa-38009-22237?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/xr-ejhxr-the-st-regis-spa---p40130-33489?wid=316&fit=constrain",
      "https://cache.marriott.com/is/image/marriotts7prod/50514591-espa_privatespasuite_outdoor_sized_tile?wid=316&fit=constrain"
    ],
    category: "SPA_LOV",
    value: "ENTHU",
    passion: "spa",
    analyticsDesc: "Spa"
  }
];

export const FollowExperiencePage = () => {
  return (
    <>
      <Heading type="h1" text="Follow Your Passions" />
      <p className={styles.subheader}>
        Follow your passions to experience the best of Marriott Bonvoy through
        personalized offers and recommendations.
      </p>
      <div className={styles.list}>
        {cards.map((card) => (
          <FollowCard
            key={card.title}
            title={card.title}
            followers={card.followers}
            images={card.images}
            category={card.category}
            value={card.value}
            passion={card.passion}
            analyticsDesc={card.analyticsDesc}
          />
        ))}
      </div>
      <PreferencesNoticeUi className={styles.notice} />
    </>
  );
};

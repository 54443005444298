import { useCallback, useEffect, useState } from "react";

export const useInfiniteScroll = (elementRef, children) => {
  const [enabled, setEnabled] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [carouselChildren, setCarouselChildren] = useState(children);

  // Checking if infinite scroll should be enabled
  useEffect(() => {
    if (children.length <= 3) {
      setEnabled(false);
      return;
    }

    setEnabled(true);
    const clone = [...children];
    setCarouselChildren([...clone, ...children, ...clone]);
  }, [children]);

  // Infinite scroll handler
  const onScroll = useCallback(() => {
    const { current } = elementRef;

    if (!current || !enabled || inProgress) {
      return;
    }

    const { scrollLeft, scrollWidth, clientWidth } = current;
    const maxScrollLeft = scrollWidth - clientWidth;
    const isStart = scrollLeft === 0;
    const isEnd = scrollLeft >= maxScrollLeft;

    if (!isStart && !isEnd) {
      return;
    }

    setInProgress(true);
    current.style.scrollBehavior = "auto";
    current.scrollLeft = isStart
      ? current.scrollWidth / 3
      : current.scrollWidth / 3 - current.clientWidth;

    requestAnimationFrame(() => {
      current.style.scrollBehavior = "smooth";
      setInProgress(false);
    });
  }, [elementRef, enabled, inProgress]);

  // Set on scroll event
  useEffect(() => {
    const { current } = elementRef;

    if (!current || !enabled) {
      return;
    }

    onScroll();
    current.addEventListener("scroll", onScroll);

    return () => current.removeEventListener("scroll", onScroll);
  }, [elementRef, enabled]);

  return carouselChildren;
};
